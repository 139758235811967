import { TezosToolkit } from '@taquito/taquito';
import { TempleWallet } from '@temple-wallet/dapp';
import { useState } from 'react';
import { chartInit } from './chart';
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"
import * as Tone from 'tone'

import wallets from './wallets';

const synth = new Tone.Synth().toDestination();

window.tururuRange = 3

function toastMsg(msg,color){
  Toastify({
    text: msg,
    backgroundColor: color || 'green',
    duration: 5000,
    position: 'center'
  }).showToast();
}

function tururuUp(){
  const now = Tone.now()
  synth.triggerAttackRelease("C4", "8n", now)
  synth.triggerAttackRelease("E4", "8n", now + 0.2)
  synth.triggerAttackRelease("G4", "8n", now + 0.4)
}

function tururuDown() {
  const now = Tone.now()
  synth.triggerAttackRelease("G4", "8n", now)
  synth.triggerAttackRelease("E4", "8n", now + 0.2)
  synth.triggerAttackRelease("C4", "8n", now + 0.4)
}

const Tezos = new TezosToolkit('https://rpc.tzbeta.net/');

async function connectWallet(fn) {
  const available = await TempleWallet.isAvailable();
  if (!available) {
    window.ga('send', 'event', 'wallet', 'not-installed');
    toastMsg("Temple Wallet não instalada","red");
  }
  TempleWallet.isAvailable()
  .then(() => {
    const mywallet = new TempleWallet('tururu');
    mywallet.connect('mainnet').then(() => {
      Tezos.setWalletProvider(mywallet);
      return mywallet.getPKH()}).then((pkh) => {
      toastMsg(`wallet: ${pkh}`,'orange');
      if(wallets.includes(pkh)){
        toastMsg("🐋 whale hunter autorizad@")
        window.ga('send', 'event', 'wallet', 'autorized', pkh);
        fn()
      } else {
        window.ga('send', 'event', 'wallet', 'not-autorized', pkh);
        toastMsg("🐋 whale hunter não autorizad@","red")
      }
    });
  })
  .catch((err) => toastMsg(err,"red"));
}

async function donate() {
  TempleWallet.isAvailable()
  .then(() => {
    const mywallet = new TempleWallet('tururu');
    mywallet.connect('mainnet').then(() => {
      Tezos.setWalletProvider(mywallet);
      Tezos.wallet
      .transfer({
        to: 'tz1Pw24gCJfukqQrPjHCFx3s1oaZGyHsB4H4',
        amount: 1,
        fee: 800,
        storageLimit: 80,
        // gasLimit: 75
      })
      .send()
      .then((op) => {
        console.log(`Waiting for ${op.opHash} to be confirmed...`)
        return op.confirmation().then(() => op.opHash);
      })
      .then(() => console.log(`Operation injected!`))
      .catch((error) => console.log(`Error: ${error} ${JSON.stringify(error, null, 2)}`));
    })
  })
  .catch((err) => console.log(err));
}

function App() {

  const [active,setActive] = useState(false)
  const [range,setRange] = useState(3)

  function openDashboard() {
    const w = window.screen.availWidth
    const h = window.screen.availHeight
    window.open(window.location.href,"tururu",`titlebar=no,menubar=no,width=${w-500},height=${h-400},top=0,left=500`)
    window.open("https://tzflow.com","flow",`titlebar=no,menubar=no,width=${w-500},height=300,top=${h-300},left=500`)
    window.open(`${window.location.href}/wallet.html`,"wallet",`width=500,height=${h}`)
  }

  function openGraph() {
    //play a middle 'C' for the duration of an 8th note
    synth.triggerAttackRelease("A3", "8n");
    
    setTimeout(()=>chartInit(tururuUp,tururuDown),1000)
    setActive(true)
  }

  function onChangeRange(e) {
    setRange(e.target.value)
    window.tururuRange = e.target.value
    synth.triggerAttackRelease("A7", "64n");
  }
  
  return (
    <div id="viewport">
      <div id="graph"></div>
      
      <p><small>
        <strong><span style={{cursor:'crosshair'}} onClick={()=>openDashboard()}>🐋</span> o famoso "tururu"</strong>&nbsp;&nbsp;
        {active && <button onClick={()=>donate()}>donate marlus.tez</button>}
      </small></p>

      {!active && (
        <div id="buttons">
          <button id="start" onClick={()=>connectWallet(openGraph)}>CONECTAR</button>&nbsp;
        </div>
      )}

      {active && (
        <div id="sensor">
          <input id="range" type="range" min="1" max="10" value={range} onChange={onChangeRange} />
          <span id="range_value">{range}%</span>
        </div>
      )}

    </div>
  );
}

export default App;
