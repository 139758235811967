const wallets = [
  'tz1Pw24gCJfukqQrPjHCFx3s1oaZGyHsB4H4', // marlus
  'tz1SvPd1aUYZSkr22JTdRzUEGMb6ZjoPEXTz', // rangel
  'tz1TG9d5ACUTVsyASTP2yi412VbW8hf2HUV6', // marcelo
  'tz1a7by2HMBv9QA8WT3t5fhu3E4XPofzcukg', // shima
  'tz1g1Qwocz15zyVtKE3Pa5o8Qnd3Rd2iK39w', // shima(2)
  'tz1Vkoqo2ZQsVXBniYJDfX6F2NMfH5RoEuGn', // sid
  'tz1XAS3EymJmeFzrQR15wyupr8QY9rdX5cfB', // marcos
  'tz1WjnWxG49QB9ikPzFUvxSoaHCaYH9vR1eZ', // arthur moura
  'tz1gtgca5mEtGVupp9sQxEERBGLfVmL9p6du', // dani (shima)
]

export default wallets