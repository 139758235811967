
import { createChart } from 'lightweight-charts';

export const chartInit = (up,down) => {

  const chartElement = document.querySelector('#graph');

  var chart = createChart(chartElement, {
      width: 800,
      height: 600,
      layout: {
          fontFamily: 'Arial',
      },
      rightPriceScale: {
          borderColor: 'rgba(197, 203, 206, 1)',
      },
      timeScale: {
          borderColor: 'rgba(197, 203, 206, 1)',
          timeVisible: true,
          secondsVisible: true,
      },
      localization: {
        dateFormat: 'yyyy/MM/dd',

      },
  });

  var areaSeries = chart.addAreaSeries({
    topColor: 'rgba(33, 150, 243, 0.56)',
    bottomColor: 'rgba(33, 150, 243, 0.04)',
    lineColor: 'rgba(33, 150, 243, 1)',
    lineWidth: 2,
  });

  let graph = []
  let last_price = 0

  const url_hash = "https://mainnet-node.madfish.solutions/chains/main/blocks/head/hash"
  const url_data = (hash) => `https://mainnet-node.madfish.solutions/chains/main/blocks/${hash}/context/contracts/KT1QxLqukyfohPV5kPkw97Rs6cw1DDDvYgbB/storage`
  
  const loadData = () => {
   fetch(`${url_hash}?time=${+ Date.now()}`, {cache: 'no-store'})
    .then((res) => res.json())
    .then((data) => {
      fetch(`${url_data(data)}?time=${+ Date.now()}`, {cache: 'no-store'})
        .then((res) => res.json())
        .then((data2) => {
          
          let tez = data2.args[1].args[0].args[1].args[2].int
          let hdao = data2.args[1].args[0].args[3].int
          
          let today = new Date()
          const nowS =  Date.parse(today)/1000-3*60*60
          
          const price = tez/hdao
          
          graph.push({
            time: nowS,
            value: price
          })
          
          areaSeries.setData(graph)
          chart.timeScale().fitContent()
          
          let diff = price / last_price              
          last_price = price
          
          const range = window.tururuRange
          
          if(diff > 1 + range * 0.01){
            up()
          } else if( diff < 1 - range * 0.01 ) {
            down()
          } 
        
        })
      })
   setTimeout (()=>loadData(),7000)
  }

  loadData()

  window.addEventListener("resize", function() {
      chart.applyOptions({ width: window.innerWidth -100, height: window.innerHeight -150 })
  });

  window.dispatchEvent(new Event('resize'));

}
